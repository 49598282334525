import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  // getDITStatList(ctx) {
  //   ctx.ditExportsList = [];
  //   const params = {
  //     action: 'R'
  //   };
  //   ctx.$store.commit('loadingDITStatListBegin');
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/settings_loader`, params).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         // console.log(response.data.data.itemslist);
  //         const listFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist));
  //         for (const item of listFromResponse) {
  //           if (
  //             !Object.prototype.hasOwnProperty.call(item, 'id') &&
  //             Object.prototype.hasOwnProperty.call(item, '_id')
  //           ) {
  //             item.id = item._id;
  //           }
  //         }
  //         ctx.ditExportsList = listFromResponse;
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('loadingDITStatListEnd');
  //     },
  //     (err) => {
  //       ctx.$store.commit('loadingDITStatListEnd');
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //     }
  //   );
  // },
  getDITStatList(ctx) {
    ctx.ditExportsList = [];
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    ctx.$store.commit('loadingDITStatListBegin');
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/settings_loader`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist);
          const listFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist));
          for (const item of listFromResponse) {
            if (
              !Object.prototype.hasOwnProperty.call(item, 'id') &&
              Object.prototype.hasOwnProperty.call(item, '_id')
            ) {
              item.id = item._id;
            }
          }
          ctx.ditExportsList = listFromResponse;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingDITStatListEnd');
      },
      (err) => {
        ctx.$store.commit('loadingDITStatListEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  updateDITExport(ctx) {
    ctx.$store.commit('updateDITExportReqBegin');
    const updatedDITExport = JSON.parse(JSON.stringify(ctx.updatedOrNewDITExport));
    const updatedDITExportId = updatedDITExport.id;
    if (
      Object.prototype.hasOwnProperty.call(updatedDITExport, 'id') &&
      Object.prototype.hasOwnProperty.call(updatedDITExport, '_id')
    ) {
      delete updatedDITExport.id;
    }
    const updateDITExportQuery = {
      action: 'U',
      items: { [updatedDITExportId]: updatedDITExport }
    };

    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/settings_loader`, updateDITExportQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('DITStat.DITStatNotificationUpdated') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            // если в ответ прилетел измененый обхъект то пытаесся его перезаписать, чтобы не запрашивать опять все объекты
            // this.updateDitItemIntoExistingDitExportsList(ctx, response.data.data.itemslist[0]);
            ctx.getAllDitExpots(ctx);
          } else {
            ctx.getAllDitExpots(ctx);
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModalForEdit();
        ctx.$store.commit('updateDITExportReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('updateDITExportReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  createDITExport(ctx) {
    ctx.$store.commit('createDITExportReqBegin');
    const updatedDITExport = JSON.parse(JSON.stringify(ctx.updatedOrNewDITExport));

    const updateDITExportQuery = {
      action: 'C',
      items: { ...updatedDITExport }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/settings_loader`, updateDITExportQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('DITStat.DITStatNotificationCreated') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            // если в ответ прилетел новый объект то пытаемся его добавить, чтобы не запрашивать опять все объекты
            // this.addDitItemIntoExistingDitExportsList(ctx, response.data.data.itemslist[0]);
            ctx.getAllDitExpots(ctx);
          } else {
            ctx.getAllDitExpots(ctx);
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModalForEdit();
        ctx.$store.commit('createDITExportReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('createDITExportReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  deleteDITExport(ctx) {
    ctx.$store.commit('deleteDITExportReqBegin');
    const updatedDITExport = JSON.parse(JSON.stringify(ctx.updatedOrNewDITExport));

    const updateDITExportQuery = {
      action: 'D',
      items: { [updatedDITExport.id]: updatedDITExport }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/settings_loader`, updateDITExportQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('DITStat.DITStatNotificationDeleted') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            // если в ответ прилетел удаленый обхъект то пытаесся его удалить, чтобы не запрашивать опять все объекты
            this.deleteDitItemIntoExistingDitExportsList(ctx, response.data.data.itemslist[0]);
          } else {
            ctx.getAllDitExpots(ctx);
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeDeleteDitModal();
        ctx.closeModalForEdit();
        ctx.$store.commit('deleteDITExportReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('deleteDITExportReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  updateDitItemIntoExistingDitExportsList(ctx, ditObject) {
    // если в ответ прилетел измененый объект то пытаемся его перезаписать, чтобы не запрашивать опять все объекты
    try {
      // ищем в списке существующих (ранее загружных) объектов объеткт ДИТ выгрузки с ID как у измененного
      // и перезаписываем его, а если что то пошло не так просто загружаем весь список из API
      const existingDitList = ctx.ditExportsList;
      const updatedObject = ditObject;
      if (
        !Object.prototype.hasOwnProperty.call(updatedObject, 'id') &&
        Object.prototype.hasOwnProperty.call(updatedObject, '_id')
      ) {
        updatedObject.id = updatedObject._id;
      }
      for (const existingDitIndex in existingDitList) {
        if (existingDitList[existingDitIndex].id === updatedObject.id) {
          Vue.set(existingDitList, existingDitIndex, updatedObject);
          return;
        }
      }
      ctx.getAllDitExpots(ctx);
    } catch (e) {
      console.log(e);
      ctx.getAllDitExpots(ctx);
    }
  },
  addDitItemIntoExistingDitExportsList(ctx, ditObject) {
    // если в ответ прилетел новый объект то пытаемся его добавить в списко, чтобы не запрашивать опять все объекты
    try {
      const existingDitList = ctx.ditExportsList;
      const newObject = ditObject;
      if (
        !Object.prototype.hasOwnProperty.call(newObject, 'id') &&
        Object.prototype.hasOwnProperty.call(newObject, '_id')
      ) {
        newObject.id = newObject._id;
      }
      existingDitList.push(newObject);
    } catch (e) {
      console.log(e);
      ctx.getAllDitExpots(ctx);
    }
  },
  deleteDitItemIntoExistingDitExportsList(ctx, ditObject) {
    // удаляем удаленый объект из списка, чтобы не запрашивать опять все объекты
    try {
      // ищем в списке существующих (ранее загружных) объектов объеткт ДИТ выгрузки с ID как у удаленного
      // и удаляем его, а если что то пошло не так просто загружаем весь список из API
      const existingDitList = ctx.ditExportsList;
      const deletedObject = ditObject;
      if (
        !Object.prototype.hasOwnProperty.call(deletedObject, 'id') &&
        Object.prototype.hasOwnProperty.call(deletedObject, '_id')
      ) {
        deletedObject.id = deletedObject._id;
      }
      for (const existingDitIndex in existingDitList) {
        if (existingDitList[existingDitIndex].id === deletedObject.id) {
          existingDitList.splice(existingDitIndex, 1);
          return;
        }
      }
      ctx.getAllDitExpots(ctx);
    } catch (e) {
      console.log(e);
      ctx.getAllDitExpots(ctx);
    }
  }
};
